import React from "react";
import LoginRedirect from './Login/Social/LoginRedirect';
import AdventureContainer from './adventure/AdventureContainer';
import SportContainer from './sport/SportContainer';
import TheoryContainer from './school/theory/TheoryContainer';
import PracticeContainer from "./school/practice/PracticeContainer";
import LecturesContainer from "./school/lectures/LecturesContainer";
import SubscriptionContainer from './subscription/SubscriptionContainer';
import Login from './Login';
import Logout from './Logout';
import NotFound from './NotFound';
import RegisterSuccess from './RegisterSuccess';
import NewPassword from './NewPassword';
import CertificateContainer from './CertificateContainer'
import Profile from './profile/ProfileContainer'
import Error from "../components/Error";

import {BrowserRouter as Router, Switch, Route as DefaultRoute, Redirect} from "react-router-dom";
import {QueryParamProvider} from "use-query-params";

import {Route} from "./generic/";
import {GlobalStateProvider} from "../lib/api/globalState";

import LoginPopup from "./common/LoginPopup";
import RefreshToken from "./RefreshToken";
import Loader from "./generic/Loader";
import {LoaderProvider} from "../lib/api/loaderState";
import '../lib/utils/i18n'
import Tablet from "./Tablet";
import TabletSportContainer from './Tablet/sport/SportContainer';
import CorpLayer from "./CorpLayer";
import {schedulePages} from './sport/Schedule/pages';
import CarrotProvider from '../lib/api/carrotProvider';
import {ContainerProvider} from "brandi-react";
import container from '../lib/ioc/index'

function App() {
    return (
        <div className="App">
            <ContainerProvider container={container}>
                <GlobalStateProvider>
                    <LoaderProvider>
                        <CarrotProvider>
                            <Router>
                                <QueryParamProvider ReactRouterRoute={DefaultRoute}>
                                    <LoginPopup/>
                                    <RefreshToken/>
                                    <Loader/>
                                    <Switch>
                                        <Route exact path={"/register/ok"} component={RegisterSuccess}/>
                                        <Route isAny path={"/travel/:url/:arrivalURL"} component={AdventureContainer}/>
                                        <Route isAny path={"/travel/:url"} component={AdventureContainer}/>
                                        <Route isAny path={"/charter/:url/:arrivalURL"} component={AdventureContainer}/>
                                        <Route isAny path={"/charter/:url"} component={AdventureContainer}/>
                                        <Route isAny path={"/sport/:event/:url"}
                                               component={() => <SportContainer page={schedulePages.slot}/>}/>
                                        <Route isAny path={"/sport/:event"}
                                               component={() => <SportContainer page={schedulePages.event}/>}/>
                                        <Route isAny path={"/sport/"}
                                               component={() => <SportContainer page={schedulePages.sport}/>}/>
                                        <Route isAny path={"/sport-events"}
                                               component={() => <SportContainer page={schedulePages.sportByEvents}/>}/>
                                        <Route isAny path={"/sport-corp/:corpSlug"}
                                               component={() => <SportContainer page={schedulePages.sportByCorp}/>}/>
                                        <Route isAny path={"/school/theory/:productURL"} component={TheoryContainer}/>
                                        <Route isAny path={"/school/theory"} component={TheoryContainer}/>
                                        <Route isAny path={"/school/practice/:url/:practiceURL"}
                                               component={PracticeContainer}/>
                                        <Route isAny path={"/school/practice/:url"} component={PracticeContainer}/>
                                        <Route isAny path={"/school/practice"} component={PracticeContainer}/>
                                        <Route isAny path={"/school/lectures/:productURL"}
                                               component={LecturesContainer}/>
                                        <Route isAny path={"/school/lectures"} component={LecturesContainer}/>
                                        <Route isAny path={"/abonement"} component={SubscriptionContainer}/>
                                        <Route isPublic path={"/connect/:providerName/redirect"}
                                               component={LoginRedirect}/>
                                        <Route isAny path={"/restore"}
                                               component={() => <Redirect to={{pathname: "/login"}}/>}/>
                                        <Route isPublic path={"/m/restore"}
                                               component={() => <Redirect to={{pathname: "/login"}}/>}/>
                                        <Route isAny path={"/new-password"} component={NewPassword}/>
                                        <Route isPublic path={"/login"} component={Login}/>
                                        <Route isPublic exact path={"/register"}
                                               component={() => <Redirect to={{pathname: "/login"}}/>}/>
                                        <Route isAny path={"/certificate"} component={CertificateContainer}/>
                                        <Route isAny path={"/giftcard"} component={CertificateContainer}/>
                                        <Route path={"/tablet/yachts"} component={TabletSportContainer}
                                               events="yachts"/>
                                        <Route path={"/tablet/sport"} component={TabletSportContainer}/>
                                        <Route path={"/tablet"} component={Tablet}/>
                                        <Route isAny path={"/logout"} component={Logout}/>
                                        <Route path={"/me"} component={Profile}/>
                                        <Route path={"/corp-layer"} component={CorpLayer}/>
                                        <Route isAny path={"/error"} component={Error}/>
                                        <Route isAny path={"/payments/:orderId"} component={Error} isPaymentError
                                               customText="Сейчас Вы будете перенаправлены на платежный шлюз"/>
                                        <Route path="/" component={NotFound}/>
                                    </Switch>
                                </QueryParamProvider>
                            </Router>
                        </CarrotProvider>
                    </LoaderProvider>
                </GlobalStateProvider>
            </ContainerProvider>
        </div>
    );
}

export default App;
